
#postContent h2 {
  text-align: center;
  font-size: 3em;
}

#postContent h3 {
  font-size: 2em;
}

#postContent h4 {
  font-size: 1.5em;
}

#postContent a {
  text-decoration: underline;
}

#postContent image {
    float: left; 
}

#postContent p {
    /* text-indent: 30px; */
    margin-bottom: 20px; 
}