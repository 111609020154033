/* .card-carousel {
  margin: 20px;
  width: 300px !important;
  padding:0;
} */
.card-carousel-img {
  width: auto;
  height: 300px;
}
.card-carousel-img-container {
  border-radius: 10px;
  box-shadow: 0 0 3px 1px #ccc;
  height: 300px;
  width: 300px;
  overflow: hidden;
}
.card-carousel-info {
  /* min-height: 100px;
  margin-top: 10px; */
}
.card-carousel-title {
  margin: 0px;
  font-size: 24px;
}
.card-carousel-subtitle {
  font-size: 16px;
  margin: 0px;
}
.overlay-container {
position: fixed; 
margin: 0;
padding: 0;
}