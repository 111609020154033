.bg-code{
    background-color: rgb(40, 42, 54);
  }
  
  .blinking-cursor::after {
    content: "|";
    animation: blink 1s step-end infinite;
  }
  @keyframes blink {
    from,
    to {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }