@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/cascadia-code.min.css");

:root {
  /* --font-base: 'Cormorant Upright', serif; */
  --font-base: "Cascadia Code", sans-serif;
  --font-alt: "Open Sans", sans-serif;
  --font-code: "Cascadia Code", sans-serif;
  --color-golden: #dcca87;
  --color-black: #0c0c0c;
  --color-gray: #545454;
  --color-crimson: #f5efdb;
  --color-grey: #aaaaaa;
  --color-white: #ffffff;
}
@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  button {
    @apply text-apple__text_black dark:text-apple__text_gray_dark;
  }
  strong {
    @apply text-apple__text_black_strong dark:text-apple__text_gray_dark_strong;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-base);
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
