
/**
  UNIT BAR
**/

#ubc7-unit {
    background: #2F5D7C; /* For White on Blue, and Grey on White */
    /*background: #002145;*/  /* For Blue on White, and White on Grey */
}


/**
  PRIMARY NAVIGATION
**/

#ubc7-unit-menu .navbar-inner {
    border-bottom: 1px solid #999999;
}

#ubc7-unit-alternate-navigation {
    border-top: 2px solid #C3D0DB;
}

#ubc7-unit-navigation .nav {
    width: 100%;
    font-size: 14px;
}

#ubc7-unit-navigation .nav .dropdown .dropdown-menu,
#ubc7-unit-alternate-navigation .nav .dropdown .dropdown-menu {
    padding: 0px 0 20px 20px;
    opacity: 0.96;
    filter: alpha(opacity = 96);
    background-color: #F0F0F0;
    border: 1px solid #999;
}

.ie7 #ubc7-unit-navigation .nav .dropdown-menu {
    margin-top: 1px;
}

#ubc7-unit-navigation .dropdown-menu > .row-fluid,
#ubc7-unit-alternate-navigation .dropdown-menu > .row-fluid {
    min-width: 446px;
}

.ie7 #ubc7-unit-navigation .dropdown-menu > .row-fluid {
    min-width: 466px;
}

#ubc7-unit-navigation .nav .dropdown .dropdown-menu .nav-header,
#ubc7-unit-alternate-navigation .nav .dropdown .dropdown-menu .nav-header {
    padding: 20px 0px 0px 0px;
    margin: 0 0 6px 1px;
    text-transform: none;
    width: 200px;
}

#ubc7-unit-navigation .nav .dropdown .dropdown-menu .nav-header,
#ubc7-unit-alternate-navigation .nav .dropdown .dropdown-menu .nav-header {
    color: #444444;
    font-size: 13px;
    border-bottom: 1px solid #999999;
}

#ubc7-unit-navigation .nav .dropdown .dropdown-menu li,
#ubc7-unit-alternate-navigation .nav .dropdown .dropdown-menu li{
    border-bottom: none;
    margin: 0px;
    padding: 0px;
    list-style: none;
}

#ubc7-unit-navigation .nav .dropdown .dropdown-menu li > a,
#ubc7-unit-alternate-navigation .nav .dropdown .dropdown-menu li > a {
    font-size: 0.8em;
    font-weight: bold;
    color: #333333;
    cursor: pointer;
    padding: 4px 0 4px 16px;
    margin-right: 15px;
    width: 190px;
    white-space: normal;
}

.ie7 #ubc7-unit-navigation .nav .dropdown .dropdown-menu li > a {
    white-space: nowrap;
}

#ubc7-unit-navigation .nav .dropdown .dropdown-menu li > a:hover,
#ubc7-unit-alternate-navigation .nav .dropdown .dropdown-menu li > a:hover {
    color: #333 !important;
}

#ubc7-unit-navigation .nav li#login-nav {
    float: right;
}

#ubc7-unit-navigation .nav li#login-nav .dropdown-menu {
    margin-left: -159px;
}
@media (max-width: 767px){
    .hidden-phone {
        display: none!important;
    }
}


/**
  CONTENT AREA
**/

.ie7 .content > .expand {
    background: #fff;
}

#ubc7-unit-identifier.no-umbrella {
    line-height: 2.25em;
}


/**
  FOOTER SIGNATURE
**/

.ie8 #ubc7-signature a {
    text-indent: -999px;
}


/**
  IE7 HELPER CLASS (FIXES FLOAT ISSUES)
**/

.ie7 .no-margin {
    margin-left: 0 !important;
}


/**
  MEDIA QUERIES
**/

/* CLF major breakpoint */
@media (min-width: 1200px) {
    /* no styles yet */
}

/* CLF major breakpoint */
@media (max-width: 979px) {
    body {
        padding: 0 20px;
    }
    #ubc7-unit-menu .navbar-inner {
        border-bottom: none;
    }
    .navbar .nav > .active > a,
    .navbar .nav > .active > a:hover,
    .navbar .nav > .active > a:focus {
        box-shadow: none;
    }
    #ubc7-unit-navigation .nav .dropdown .dropdown-menu,
    #ubc7-unit-alternate-navigation .nav .dropdown .dropdown-menu {
        opacity: 0.90;
        filter: alpha(opacity = 90);
        border: none;
    }
    #ubc7-unit-navigation .nav .dropdown .dropdown-menu .nav-header,
    #ubc7-unit-alternate-navigation .nav .dropdown .dropdown-menu .nav-header {
        width: 94%;
        text-transform: uppercase;
        font-size: 14px;
    }
    #ubc7-unit-navigation .nav .dropdown .dropdown-menu li > a,
    #ubc7-unit-alternate-navigation .nav .dropdown .dropdown-menu li > a {
        width: 94%;
        font-size: 13.5px;
        font-weight: normal;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    #ubc7-unit-navigation .nav li#login-nav {
        float: none;
    }
    #ubc7-unit-navigation .nav li#login-nav .dropdown-menu {
        margin-left: 0;
    }

}

/* CLF major breakpoint */
@media (max-width: 767px) {
    /* no styles yet */
}

/* CLF major breakpoint */
@media (max-width: 319px) {
    /* no styles yet */
}

/* LWS-4663 */
.alert-error a, .alert-error a:visited {
    color:white;
}
/* missing in 7.0.4 */
.dropdown-menu li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 20px;
    color: #333;
    white-space: nowrap;
}
/*change default color to pass accessbility */
.breadcrumb>li>.divider {
    color: #000;
}
