html {
  scroll-behavior: smooth;
}

.custom__button {
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border: none;
  outline: none;
}
/* https://stackoverflow.com/questions/70906977/tailwind-underline-hover-animation 
  Underline animation for timeline Detail
*/
.link-underline-animated {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

.link-underline-animated-black {
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(rgb(19, 55, 155), rgb(88, 73, 224));
}

.link-underline-animated:hover {
  background-size: 100% 3px;
  background-position: 0 100%;
}

